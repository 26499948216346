import axios from "axios"

import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
}

export const getters = {
  ...BaseGetters,
}

export const actions = {
  ...BaseActions,
  update: ({ dispatch, commit }, { payload, parentId }) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")
    return axios
      .put("widget_kanban_boards/" + payload.id, payload.params)
      .then((response) => {
        commit("SET_MODEL", response.data.widget_kanban_board)
        dispatch("widgets/refreshWidget", parentId, { root: true })
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
      })
  },
}

export const mutations = {
  ...BaseMutations,
}
