import axios from "axios"
import SearchMixin from "@mixins/search"
import findIndex from "lodash/findIndex"
import StaticHelper from "@mixins/static-helpers"
import CustomAttrs from "@mixins/custom-attributes"
import Vue from "vue"
import {
  WidgetTypes,
  WidgetTypesHumanReadable,
  WidgetSubmitVerbs,
} from "@mixins/widgets/types"
import { ConfigureContexts } from "@mixins/app-contexts"

import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
  addWidgetDialogIsOpen: false,
}

export const getters = {
  ...BaseGetters,
  hasWidgets(state) {
    return !StaticHelper.blank(state.all)
  },
  hasPigeonholeWidget(state) {
    return !!_.find(state.all, ["use_case", WidgetTypes.PIGEONHOLE_EMBED])
  },
  addWidgetDialogIsOpen(state) {
    return state.addWidgetDialogIsOpen
  },
}

export const actions = {
  ...BaseActions,
  // Overwrite the base create method because widgets has a special case
  create: ({ commit }, payload) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")

    axios
      .post("widgets", payload.params)
      .then((response) => {
        // Assigning this attribute allows us to run custom actions if widget is newly created
        response.data.widget.isNew = true
        commit("SET_MODEL", response.data.widget)
        commit("UNSHIFT_ALL", response.data.widget)

        if (
          response.data.widget.use_case === WidgetTypes.FILTER_GRAPH ||
          response.data.widget.use_case === WidgetTypes.FIELD_GRAPH ||
          response.data.widget.use_case === WidgetTypes.TEXT_ENTRY ||
          response.data.widget.use_case === WidgetTypes.KANBAN_BOARD ||
          response.data.widget.use_case === WidgetTypes.SECTOR_MAP ||
          response.data.widget.use_case === WidgetTypes.PIGEONHOLE_EMBED ||
          response.data.widget.use_case === WidgetTypes.VIDEO_EMBED
        ) {
          commit("OPEN_WIDGET_MODAL", {
            widget: response.data.widget,
          })
        }
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
      })
  },
  delete: ({ commit, dispatch, store }, payload) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")

    axios
      .delete("widgets/" + payload.id)
      .then(() => {
        commit("SET_MODEL", null)
        commit("REMOVE_FROM_COLLECTION", payload.id)
        dispatch("updateDashboardMeta", {
          context_view_id: _.get(payload, "context_view_id"),
          widget_id_order_array: store.all.map((x) => x.id),
        })
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
      })
  },
  update: ({ commit }, payload) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")

    return axios
      .put("widgets/" + payload.id, payload.params)
      .then((response) => {
        commit("SET_MODEL", response.data.widget)
        commit("UPDATE_IN_COLLECTION", response.data.widget)
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
      })
  },
  refreshWidget({ commit }, id) {
    this.dispatch("widgets/fetchWidget", id).then((widget) => {
      commit("UPDATE_IN_COLLECTION", widget)
    })
  },
  fetchContextWidgets: ({ commit }, { context, page }) => {
    commit("SET_LOADING", true)
    axios
      .get("search", {
        params: {
          q: SearchMixin.buildSearchObject({
            indices: ["widgets"],
            includes: SearchMixin.widgetIncludes,
            filters: buildContextFilters(context),
            sorts: [{ attr: "created_at", order: "desc" }],
            customAttrs: [CustomAttrs.widgetRelationalData()],
            per_page: 1000,
            page: page,
          }),
        },
      })
      .then((response) => {
        commit("SET_META", response.data.meta)
        if (response.data.results === undefined) {
          commit("RESET_STATE")
          commit("SET_LOADING", false)
          return
        }
        // sort widgets based on widget id order array
        if (context.widgetIdOrderArray) {
          const orderedResponses = _.sortBy(
            response.data.results,
            function (result) {
              return context.widgetIdOrderArray.indexOf(result.id)
            }
          )

          response.data.results = orderedResponses
        }

        // After results have been parsed and set,
        // freeze the response object for performance.
        Object.freeze(response)

        commit("PUSH_ALL", response)
        commit("SET_LOADING", false)
      })
      .catch((e) => {
        console.error(_.get(e, "response.data.errors"))
        commit("SET_LOADING", false)
      })
  },
  fetchWidget: ({ commit }, id) => {
    return axios.get(`/widgets/${id}`).then((response) => {
      const widget = response.data.widget
      commit("SET_MODEL", widget)
      return widget
    })
  },
  openSavedFilterDialog: ({ commit }, { meta, switchContext = false }) => {
    commit("OPEN_SAVED_FILTER_DIALOG", { meta, switchContext })
  },
  updateDashboardMeta: ({ commit, dispatch, state, rootGetters }, payload) => {
    commit("SET_LOADING", true)
    dispatch(
      "contextViews/updateView",
      {
        id: payload.context_view_id,
        parent_id: payload.parent_id,
        parent_type: payload.parent_type,
        params: {
          context_data: JSON.stringify({
            widget_id_order_array: payload.widget_id_order_array,
          }),
        },
      },
      {
        root: true,
      }
    )
      .then(() => {
        commit("SET_ALL_WIDGETS", payload.widgetList)
      })
      .finally(() => {
        commit("SET_LOADING", false)
      })
  },
  setAddWidgetDialog: ({ commit }, boolean) => {
    commit("SET_ADD_WIDGET_DIALOG", boolean)
  },
}

export const mutations = {
  ...BaseMutations,
  SET_ALL_WIDGETS(state, widgets) {
    state.all = widgets
  },
  UPDATE_IN_COLLECTION(state, widget) {
    const storeIndex = findIndex(state.all, function (w) {
      return w.id === widget.id
    })
    Vue.set(state.all, storeIndex, widget)
  },
  REMOVE_FROM_COLLECTION(state, id) {
    const storeIndex = findIndex(state.all, function (w) {
      return w.id === id
    })
    state.all.splice(storeIndex, 1)
  },
  UNSHIFT_ALL(state, widget) {
    state.all.unshift(widget)
  },
  OPEN_WIDGET_MODAL(state, { widget }) {
    this.dispatch("app/setActiveDialog", {
      dialog: "activeModal",
      context: "WidgetModal",
      title: `${WidgetTypesHumanReadable[widget.use_case]}`,
      meta: {
        model: widget,
        savedFilters: [],
        actionSet: {
          set: "crud",
          action: "edit",
          submitVerb: WidgetSubmitVerbs[widget.use_case],
        },
      },
    })
  },
  OPEN_SAVED_FILTER_DIALOG(state, { meta, switchContext = false }) {
    const dialogConfig = {
      title: "Configure Filters",
      dialog: "activeModal",
      context: "ConfigureRelationalListModal",
      switchContextWithinDialog: switchContext,
      meta: {
        parent: meta.model,
        configContext: ConfigureContexts.SAVED_FILTER_RELATION,
        itemType: "Filter",
        action: "savedFilters/fetchSavedFilters",
        selectedItems: meta.savedFilters,
        allItemsSource: "savedFilters",
        filterAttr: "id",

        createModal: {
          title: "Create Filter",
          dialog: "activeModal",
          context: "SavedFilterModal",
          switchContextWithinDialog: true,
          meta: {
            callback: {
              backTo: {
                title: "Configure Filters",
                dialog: "activeModal",
                context: "ConfigureRelationalListModal",
                cancelButtonText: "Back",
              },
            },
          },
        },
      },
    }
    if (meta.addBackTo) {
      dialogConfig.meta.callback = {
        backTo: {
          title: "Edit Widget",
          dialog: "activeModal",
          context: "WidgetModal",
          cancelButtonText: "Back",
          meta: meta,
        },
      }
    }
    this.dispatch("app/setActiveDialog", dialogConfig)
  },
  SET_ADD_WIDGET_DIALOG(state, boolean) {
    state.addWidgetDialogIsOpen = boolean
  },
}

// ===
// Private helpers
// ===

function buildContextFilters(context) {
  const filters = []

  if (context.report) {
    filters.push({ attr: "report_id", type: "term", value: context.report.id })
  }

  if (context.noReport) {
    filters.push({
      attr: "report_id",
      type: "range",
      value: { gte: 0 },
      verb: "must_not",
    })
  }

  if (context.group) {
    filters.push({ attr: "group_id", type: "term", value: context.group.id })
  }

  if (context.field) {
    filters.push({ attr: "field_id", type: "term", value: context.field.id })
  }

  if (context.contextView) {
    filters.push({
      attr: "context_view_id",
      type: "term",
      value: context.contextView.id,
    })
  }

  return filters
}
