import axios from "axios"
import SearchMixin from "@mixins/search"

import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
  selectableUsers: [],

  // Parent Group exists in shared cases and is the owner of a field, where True Group is the currently authed group.
  // When we cache a set of users, we also cache the ids from these values which stand for the "lookup context".
  // If the lookup context changes, meaning if these values change, that's how we know to re-cache the user set.
  selectableParentGroupId: null,
  selectableTrueGroupId: null,
}

export const getters = {
  ...BaseGetters,
  selectableUsers: (state) => {
    return _.get(state, "selectableUsers")
  },
}

export const actions = {
  ...BaseActions,
  fetchUser({ commit }, { id, params = " " }) {
    return axios.get(`users/${id}`, { q: params }).then((response) => {
      const user = response.data.user
      commit("CACHE_USER", user)
      return user
    })
  },
  fetchUsers({ commit }, groupId) {
    const context = {
      indices: ["users"],
      includes: ["name", "avatar_url", "email"],
      per_page: 5000,
      filters: [
        {
          attr: "access_policy",
          type: "terms",
          value: SearchMixin.buildAccessPolicyWords({
            groups: [{ id: groupId, action: "read" }],
          }),
        },
      ],
    }

    const searchObject = SearchMixin.buildSearchObject(context)
    return new Promise((resolve, reject) => {
      axios
        .get("search", { params: { q: searchObject } })
        .then((res) => {
          commit("SET_ALL", res)
          resolve()
        })
        .catch((e) => {
          console.error(e)
          commit("SET_ERRORS", e)
          reject(e)
        })
    })
  },
  fetchSelectableUsers({ state, commit }, args) {
    // Determine if the lookup context has changed and then cache the lookup context
    let lookupContextChanged = false
    if (
      !_.isEmpty(args.parentGroup) &&
      state.selectableParentGroupId != args.parentGroup.id
    ) {
      lookupContextChanged = true
      commit("CACHE_PARENT_GROUP", args.parentGroup.id)
    }
    if (
      !_.isEmpty(args.trueGroup) &&
      state.selectableTrueGroupId != args.trueGroup.id
    ) {
      lookupContextChanged = true
      commit("CACHE_TRUE_GROUP", args.trueGroup.id)
    }

    // Return cached users if the lookup context has not changed
    if (!lookupContextChanged && !_.isEmpty(state.selectableUsers)) {
      return new Promise((resolve, reject) => {
        resolve(state.selectableUsers)
      })
    }

    // Fetch and cache new user set
    const context = {
      indices: ["users"],
      includes: ["name", "avatar_url", "email"],
      per_page: 5000,
      max_policy_set: !!args.parentGroup,
      filters: [
        {
          attr: "access_policy",
          type: "terms",
          value: SearchMixin.buildAccessPolicyWords({
            groups: [{ id: args.trueGroup.id, action: "read" }],
          }),
        },
      ],
    }
    const searchObject = SearchMixin.buildSearchObject(context)
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      axios
        .get("search", { params: { q: searchObject } })
        .then((res) => {
          let users = _.get(res, "data.results")
          commit("CACHE_SELECTABLE_USERS", users)
          resolve(users)
        })
        .catch((e) => {
          console.error(e)
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
}

export const mutations = {
  ...BaseMutations,
  CACHE_USER(state, newUser) {
    state.all.push(newUser)
  },
  CACHE_PARENT_GROUP(state, id) {
    state.selectableParentGroupId = id
  },
  CACHE_TRUE_GROUP(state, id) {
    state.selectableTrueGroupId = id
  },
  CACHE_SELECTABLE_USERS(state, users) {
    state.selectableUsers = users
  },
}
