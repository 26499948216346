import axios from "axios"

import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
}

export const getters = {
  ...BaseGetters,
}

export const actions = {
  ...BaseActions,
  update: ({ commit }, { payload, parentId }) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")
    var id = parentId
    return axios
      .put("widget_filter_graphs/" + payload.id, payload.params)
      .then((response) => {
        commit("SET_MODEL", response.data.widget_filter_graph)
        commit("REFRESH_PARENT", id)
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
      })
  },
}

export const mutations = {
  ...BaseMutations,
  REFRESH_PARENT(state, parentId) {
    this.dispatch("widgets/refreshWidget", parentId)
  },
}
