export const state = {
  exportXLSX: false,
  exportParentUid: null,
}

export const getters = {
  exportXLSX: (state) => {
    return state.exportXLSX
  },
  exportParentUid: (state) => {
    return state.exportParentUid
  },
}

export const actions = {
  exportXLSX({ commit }, meta) {
    commit("EXPORT_XLSX", meta)
  },
  setExportParentUid({ commit }, exportParentUid) {
    commit("EXPORT_PARENT_UID", exportParentUid)
  },
}

export const mutations = {
  EXPORT_XLSX(state, meta) {
    state.exportParentUid = meta.parentUid || state.exportParentUid
    state.exportXLSX = !state.exportXLSX
  },
  EXPORT_PARENT_UID(state, exportParentUid) {
    state.exportParentUid = exportParentUid
  },
}
