import Vue from "vue"
import Vuex from "vuex"

import modules from "./modules"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  getters: {
    isMobile: (state, getters, rootState) => {
      return (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) || window.innerWidth <= 960
      )
    },
  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
})

export default store
