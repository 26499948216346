import {
  DataSources,
  DataSourceLogos,
  DataSourcesHumanReadable,
} from "@mixins/data-sources"

export const state = {
  params: null,
}

export const getters = {
  params: (state) => {
    return state.params
  },
  group: (state) => {
    return _.get(state, "params.group", null)
  },
  dataSource: (state) => {
    return _.get(state, "params.data_source", null)
  },
  solutionInstanceId: (state) => {
    return _.get(state, "params.solution_instance_id", null)
  },
  isContainerWorkflow: (state) => {
    return _.get(state, "params.isContainerWorkflow", false)
  },
  isTrayWorkflow: (state) => {
    return _.get(state, "params.isTrayWorkflow", false)
  },
  isTypeformWorkflow: (state) => {
    return _.get(state, "params.isTypeformWorkflow", false)
  },
  // ---
  // Branding
  // ---
  integrationName: (state) => {
    return DataSourcesHumanReadable[_.get(state, "params.data_source", null)]
  },
  integrationLogo: (state) => {
    return DataSourceLogos[_.get(state, "params.data_source", null)]
  },
  integrationColor: (state) => {
    if (
      _.isEqual(
        _.get(state, "params.data_source", null),
        DataSources.SALESFORCE
      )
    ) {
      return "#17A0DB"
    } else if (
      _.isEqual(
        _.get(state, "params.data_source", null),
        DataSources.MICROSOFT_DYNAMICS_365
      )
    ) {
      return "#4054BB"
    } else if (
      _.isEqual(_.get(state, "params.data_source", null), DataSources.AFFINITY)
    ) {
      return "#2265f1"
    } else if (
      _.isEqual(_.get(state, "params.data_source", null), DataSources.TYPEFORM)
    ) {
      return "#262627"
    } else {
      return null
    }
  },
}

export const actions = {
  setParams({ commit }, params) {
    // params:
    //  required: group, data_source
    //  optional: solution_instance_id, isContainerWorkflow, isTrayWorkflow, isTypeformWorkflow
    commit("SET_PARAMS", params)
  },
}

export const mutations = {
  SET_PARAMS(state, params) {
    state.params = params || null
  },
}
