/* eslint-disable */
import axios from "axios"

export const actions = {
  fetchTimedAction({ secret }, params) {
    return axios
      .get("/snowflakes/timed_actions/get_timed_action", { params })
      .then((response) => {
        const timedAction = response.data.timed_action
        return timedAction
      })
  },
  fetchTimedActionUserInvite({ secret }, params) {
    return axios
      .get("/snowflakes/timed_actions/get_user_invitation_action", { params })
      .then((res) => {
        return res.data.timed_action
      })
  },
}
